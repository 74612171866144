import React from "react";
import SEO from "@src/components/SEO";
import Container from "@src/components/Container";
import { Flex } from "@src/components/Boxes";
import { Title, Space } from "@src/components/nessie-web";
import styled from "@emotion/styled";
import { mediaQueriesMax } from "@src/styles/theme";

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  gap: 50px;
  padding-block: 80px;
  align-items: center;
  ${mediaQueriesMax[1]} {
    flex-direction: column;
  }
`;

const IdeaConLP = () => {
  return (
    <div css={{ backgroundColor: "#E1F8FF" }}>
      <SEO title="Ideacon 2024 Outreach" noindex={true} />
      <FlexContainer>
        <div css={{ flex: 1, display: "flex", flexDirection: "column", gap: 30 }}>
          <Title size="2">Thanks for connecting with a member of our ClassDojo community!</Title>
          <Title size="1">Share your info with us and we'll send you a special Dojo Island activity.</Title>
        </div>
        <div css={{ flex: 1, backgroundColor: "#ffffff", borderRadius: "30px", padding: "24px 48px" }}>
          <iframe
            src="https://learn.classdojo.com/l/1046033/2024-02-19/3grg"
            width="100%"
            height="400"
            // type="text/html"
            css={{ border: 0 }}
            title="Form"
          ></iframe>
        </div>
      </FlexContainer>
    </div>
  );
};

export default IdeaConLP;
